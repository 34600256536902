<template>
	<div class="search-result-box">
		<indexPc class="result-pc"></indexPc>
		<div
			class="result-mobile Main width_1200 margin_auto justify_content_sb search"
		>
			<div class="searchBox flex align_items_c">
				<img
					src="https://res.metaera.hk/resources/assets/images/tab/h_search.png"
					alt=""
					@click="searchFun"
					class="pointer"
				/>
				<input type="text" v-model="keywords" @keydown.enter="searchFun" />
			</div>
			<div class="flex">
				<div class="MainLeft">
					<div class="tagBoxCon">
						<div class="tageBox">
							<el-tabs v-model="activeName">
								<el-tab-pane label="综合" name="1">
									<zh_searchkeyword
										:searchObj="searchObj"
										@changeActiveName="changeActiveName"
									>
									</zh_searchkeyword>
								</el-tab-pane>
								<el-tab-pane label="文章" name="3">
									<wz :searchObj="searchObj"></wz>
								</el-tab-pane>

								<el-tab-pane label="快讯" name="4">
									<div class="steps kxsteps" v-if="searchObj.flash_list">
										<el-steps
											direction="vertical"
											:active="1"
											class="wzitem_main"
											style="margin-top: 10px"
										>
											<div
												class="wzItemS pointer"
												v-for="(item, index) in searchObj.flash_list"
												:key="index"
											>
												<router-link
													target="_blank"
													:to="{ name: '/gw_detail', params: { id: item.id } }"
												>
													<wzitem :item="item"></wzitem>
												</router-link>
											</div>
										</el-steps>
									</div>
								</el-tab-pane>
								<el-tab-pane label="专栏作者" name="6">
									<author :searchObj="searchObj"></author>
								</el-tab-pane>
								<el-tab-pane label="活动" name="7">
									<div
										v-for="(item, index) in searchObj.activity_list"
										:key="index"
									>
										<acItem :item="item" :type="1" @gotoDetail="gotoDetail">
										</acItem>
									</div>
								</el-tab-pane>
							</el-tabs>
						</div>
					</div>
				</div>
				<div class="MainRight">
					<div class="MainRight_3">
						<div class="MainRight_3_title">推荐专栏作者</div>
						<div class="wzitem_main MainRight_3_main">
							<div
								class="zlzzItem flex pointer"
								v-for="(item, index) in popularColumn"
								:key="index"
								@click="
									$router.push({
										name: '/home_wz',
										params: { id: item.userInfo.id },
									})
								"
							>
								<img
									v-if="
										item.userInfo != null &&
										item.userInfo.avatar_img != '' &&
										item.userInfo.avatar_img != null
									"
									:src="item.userInfo.avatar_img"
									:key="item.userInfo.avatar_img"
									alt=""
								/>
								<img
									src="https://res.metaera.hk/resources/user_header.png"
									alt=""
									v-else
								/>
								<div class="zlzzItem_r">
									<div
										v-if="
											item.userInfo != null &&
											item.userInfo.nickname != '' &&
											item.userInfo.nickname != null
										"
										>{{ item.userInfo.nickname }}</div
									>
									<div v-else>MetaEra用户</div>
									<div
										class="zlzzItem_desc"
										v-if="
											item.userInfo != null &&
											item.userInfo.desc != '' &&
											item.userInfo.desc != null
										"
										>{{ item.userInfo.desc }}</div
									>
									<div v-else>全球领先的中文区块链媒体及服务平台</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AraleQRCode from "arale-qrcode";
import zhuanti from "./zhuanti.vue";
import wz from "./wz.vue";
import zh_searchkeyword from "./zh_searchkeyword.vue";
import author from "./author.vue";
import message from "./message.vue";
import { search, getPopularColumn } from "@/api/home";
import { myMixin } from "@/utils/mixin.js";
import wzitem from "./../../components/pc/wzitem.vue";
import acItem from "./acItem.vue";
import indexPc from "./index_pc.vue";
export default {
	mixins: [myMixin],
	name: "",
	components: {
		zhuanti,
		wz,
		zh_searchkeyword,
		author,
		wzitem,
		message,
		acItem,
		indexPc,
	},
	data() {
		return {
			tupian: "",
			activeName: "1",
			showsx: false,
			type: "",
			time_type: "5",
			order_type: "3",
			searchObj: {},
			popularColumn: [],
			keywords: "",
			category: [
				{
					name: "顺序",
					key: "order_type",
					order_type: "3",
					list: [
						{
							name: "互动最多",
							val: "1",
						},
						{
							name: "阅读最多",
							val: "2",
						},
						{
							name: "最新发布",
							val: "3",
						},
					],
				},
				{
					name: "时间",
					key: "time_type",
					time_type: "5",
					list: [
						{
							name: "一天内",
							val: "1",
						},
						{
							name: "一周内",
							val: "2",
						},
						{
							name: "一个月内",
							val: "3",
						},
						{
							name: "三个月内",
							val: "4",
						},
						{
							name: "一年内",
							val: "5",
						},
					],
				},
			],
			config: {},
		};
	},
	created() {
		this.keywords = this.$route.params.key;
		this.search(this.$route.params.key);
		this.getPopularColumn();
	},
	mounted() {},
	methods: {
		gotoDetail(item) {
			this.$router.push({
				name: "/ac_detail",
				params: { id: item.id },
			});
		},
		handleEnterKey() {
			this.searchFun();
		},
		changeActiveName(val) {
			this.activeName = val;
			// console.log(this.activeName);
		},
		search(key) {
			if (key.length > 0) {
				let _obj = JSON.parse(sessionStorage.getItem("searchRecords"));
				if (_obj == null) {
					_obj = [];
				}
				var tmpData = { name: key };
				_obj.push(tmpData);
				sessionStorage.setItem("searchRecords", JSON.stringify(_obj));
			}
			search({
				key: key,
				type: this.type,
				time_type: this.time_type,
				order_type: this.order_type,
			})
				.then((res) => {
					if (res.code == 200) {
						this.searchObj = res.data;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		searchFun() {
			this.search(this.keywords);
		},
		getPopularColumn() {
			getPopularColumn({ type: 5 })
				.then((res) => {
					if (res.code == 200) {
						this.popularColumn = res.data.list;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		changeCate(item, items, indexs) {
			item[item.key] = items.val;
			item.key == "order_type"
				? (this.order_type = item.order_type)
				: (this.time_type = item.time_type);
			this.searchFun();
			this.showsx = false;
		},
	},
};
</script>

<style scoped>
.search-result-box {
	min-height: calc(100vh - 100px);
}
.result-pc {
	display: block;
}
.result-mobile {
	display: none;
}
.Main {
	padding-top: 31px;
	padding-bottom: 30px;
}

.MainLeft {
	width: 750px;
}

.searchBox {
	width: 100%;
	height: 54px;
	background: #ffffff;
	box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
	border-radius: 33px 33px 33px 33px;
	opacity: 1;
}

.searchBox img {
	width: 22px;
	height: 22px;
	margin-left: 20px;
	margin-right: 10px;
}
.searchBox input {
	width: 80%;
	height: 90%;
	border: none;
	outline: none;
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.tagBoxCon {
	margin-top: 29px;
	position: relative;
}

.tagBox {
	width: 100%;
}

.sxBtn {
	position: absolute;
	right: 0;
	top: 10px;
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #767680;
}

.MainRight {
	margin-left: 30px;
	width: 300px;
}

.MainRight_1 {
	width: 100%;
	height: 104px;
	background: #f9f9f9;
	border-radius: 8px 8px 8px 8px;
	opacity: 0.6;
}

.MainRight_1_left {
	margin-left: 24px;
}

.MainRight_1_left img {
	width: 55px;
	height: 55px;
}

.MainRight_1_Right {
	margin-left: 18px;
}

.MainRight_1_Right div:nth-child(1) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.MainRight_1_Right div:nth-child(2) {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a1a1ab;
	line-height: 17px;
	margin-top: 3px;
}

.newsImgE {
	width: 130px;
	margin-left: 16px;
}

.MainRight_2 {
	margin-top: 76px;
	padding-left: 0;
	border: 0;
}

.MainRight_3 {
	margin-top: 36px;
}
.MainRight_3_main {
	margin-top: 10px;
	padding-top: 10px;
	padding-left: 20px;
	padding-right: 20px;
}
.MainRight_3_title {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	padding-bottom: 18px;
	border-bottom: 1px solid #f5f5f5;
}

.zlzzItem {
	margin-top: 20px;
}

.zlzzItem img {
	border-radius: 50px;
	width: 45px;
	height: 45px;
}

.zlzzItem_r {
	padding-bottom: 20px;
	border-bottom: 1px solid #f5f5f5;
	margin-left: 13px;
	height: 60px;
}
.zlzzItem_desc {
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.zlzzItem_r div:nth-child(1) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.zlzzItem_r div:nth-child(2) {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #a0a0a9;
	line-height: 19px;
}

.MainRight_3_title {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.sxBox {
	width: 1200px;
	position: absolute;
	top: 0;
	right: 0;
}

.sxItem {
	margin-top: 20px;
}

.sxItem:nth-child(1) {
	margin-top: 0;
}

.sxItem_title {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.sxItem_btn_list {
	margin-top: 18px;
}

.sxItem_btn_item {
	margin-left: 12px;
	padding: 0 22px;
	height: 30px;
	background: #ffffff;
	border-radius: 16px 16px 16px 16px;
	opacity: 1;
	border: 1px solid #f5f5f5;
}

.sxItem_btn_item:nth-child(1) {
	margin-left: 0;
}

.sxCon {
	padding: 20px 0 30px 35px;
	position: absolute;
	top: 60px;
	background: #fff;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	width: calc(100% - 35px);
}
.activeSx {
	border: 1px solid #0056ff;
	color: #0056ff;
}
.btnitem {
	width: 80px;
}
@media screen and (max-width: 900px) {
	.result-pc {
		display: none;
	}
	.result-mobile {
		display: block;
	}
	.el-step .is-vertical,
	.el-step__main,
	.is-vertical {
		height: calc(100vw * 80 / 375) !important;
		flex-basis: auto !important;
	}
	.el-step__description {
		line-height: calc(100vw * 30 / 375);
	}
	.stepsTime {
		font-size: calc(100vw * 14 / 375);
		height: calc(100vw * 14 / 375);
		/* margin-top: calc(100vw * -13 / 375); */
	}
	.kxsteps .stepsVal {
		font-size: calc(100vw * 16 / 375);
		height: calc(100vw * 20 / 375);
		padding-top: calc(100vw * 10 / 375);
	}
	.kxstepsBox {
		height: calc(100vw * 15 / 375);
	}
	.kxstepsBox a {
		font-size: calc(100vw * 12 / 375);
	}
	.kxsteps .btnitem div,
	.kxsteps .btnitem a {
		font-size: calc(100vw * 12 / 375);
	}
	.btnitem {
		width: calc(100vw * 60 / 375);
	}
	.btnitem img {
		width: calc(100vw * 12 / 375);
		height: calc(100vw * 12 / 375);
	}
}
@media screen and (max-width: 900px) {
	.MainRight {
		display: none;
	}
	.MainLeft {
		width: 90%;
		margin: 0 auto;
	}
	.searchBox {
		width: 90%;
		margin: 0 auto;
		height: calc(100vw * 40 / 375);
		background: #333;
		box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
		border-radius: calc(100vw * 20 / 375);
		opacity: 1;
	}

	.searchBox input {
		width: 75%;
		height: 90%;
		border: none;
		outline: none;
		border-radius: 33px 0px 0px 33px;
		font-size: calc(100vw * 14 / 375);
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 600;
		color: #fff;
		background: transparent;
	}

	.searchBox img {
		width: calc(100vw * 14 / 375);
		height: calc(100vw * 14 / 375);
		margin-right: calc(100vw * 20 / 375);
	}

	.rsTitle {
		font-size: calc(100vw * 16 / 375);
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 600;
		color: #000000;
		margin-top: 52px;
	}
	.tagBoxCon {
		margin-top: calc(100vw * 20 / 375);
		/* height: calc(100vw * 40 / 375); */
		position: relative;
	}

	.tagBox {
		width: 100%;
	}

	.sxBtn {
		position: absolute;
		right: 0;
		top: -10px;
		font-size: calc(100vw * 14 / 375);
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 400;
		color: #767680;
	}
}
.tagBoxCon .el-tabs__nav-wrap::after {
	background-color: #f5f5f5;
}

.tagBoxCon .el-tabs__item {
	height: 46px;
}
</style>
<style>
.btnitem img {
	height: 16px;
}

.btnitem div {
	margin-left: 4px;
	height: 18px;
	font-size: 14px;
}
.el-popover {
	height: 284px;
	top: 256px !important;
	left: 0 !important;
	width: 750px !important;
}

@media screen and (min-width: 800px) {
	.el-popover {
		height: 284px;
		top: 256px !important;
		left: 200px !important;
		width: 750px !important;
	}
}
</style>

<style>
.el-step__main {
	margin-left: 16px !important;
	margin-top: 0 !important;
	padding-bottom: 10px;
}
.stepsTime {
	margin-bottom: 10 !important;
}
@media screen and (max-width: 900px) {
	.result-mobile .el-tabs__nav {
		border: 0;
	}
	.result-mobile .tageBox .el-tabs__item {
		height: 40px !important;
		line-height: 40px !important;
	}
	.tageBox
		.el-tabs
		.el-tabs__header
		.el-tabs__nav-wrap
		.el-tabs__nav-scroll
		.el-tabs__nav
		.el-tabs__item {
		font-size: calc(100vw * 16 / 375) !important;
	}
	.el-tabs__nav-wrap::after {
		display: none;
	}
	.el-tabs__active-bar {
		/* margin-top: calc(100vw * 20 / 375) !important; */
		/* width: calc(100vw * 20 / 375) !important; */
		display: none;
	}
	.steps .kxsteps {
		padding-top: calc(100vw * 6 / 375) !important;
	}
	.kxsteps {
		padding-top: calc(100vw * 6 / 375) !important;
	}
	.steps .el-step__icon {
		width: calc(100vw * 16 / 375) !important;
		height: calc(100vw * 16 / 375) !important;
	}
	.steps .el-step.is-vertical .el-step__line {
		left: calc(100vw * 7 / 375);
	}
	.el-step__main {
		margin-left: calc(100vw * 16 / 375) !important;
		margin-top: calc(100vw * -3 / 375) !important;
	}
	.el-step.is-vertical .el-step__main {
		padding-left: 0px !important;
	}
}
</style>
